<template>
    <div class="SearchBar">
        <input :id="'SearchBar-' + _uid" type="text" :placeholder="placeholder == 'Buscar por nickname'
                ? $t('filter_nickname')
                : placeholder
            " v-model="text" v-on:keyup="keyUp($event)" />
        <i class="fa fa-search"></i>
    </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
export default {
    name: 'SearchBar',
    props: {
        placeholder: {
            type: String,
            default: 'Buscar por nickname',
        },
        autocompleteListItems: {
            type: Array,
            default: function () {
                return []
            },
        },
    },
    data() {
        return {
            text: '',
            hasSuggestions: false,
            autocompleteReady: false,
            prevListLenght: 0,
            isEmail: false,
            _uid: getCurrentInstance().uid,
            hasIdentifier: false
        }
    },
    mounted() {
        this.VerifyEmail(this.text)
        if (this.autocompleteListItems.length > 0)
            this.autocompleteList(
                document.getElementById('SearchBar-' + this._uid),
                this.autocompleteListItems
            )
    },
    updated() {
        this.VerifyEmail(this.text)
        if (this.autocompleteListItems.length > 0 && this.autocompleteListItems.length !== this.prevListLenght) {
            this.prevListLenght = this.autocompleteListItems.length
            this.autocompleteList(
                document.getElementById('SearchBar-' + this._uid),
                this.autocompleteListItems
            )
        }
    },
    methods: {
        reset: function () {
            this.text = ''
        },
        keyUp(event) {
            const regex = /^[0-9]+$/;
            this.hasIdentifier = regex.test(this.text);
            this.$emit('key-up', event, this.text)
            if (this.autocompleteListItems.length == 0) {
                this.$emit('item-selected', {
                    text: this.text,
                    type: this.hasSuggestions ? 'filter' : (this.hasIdentifier ? 'identifier' : 'nickname'),
                })
            }
        },
        async VerifyEmail(email) {
            if (this.text !== '') {
                email = this.text.replace(/\s/g, '')
                this.isEmail = this.verifyEmail(email)
                if (this.isEmail == null) {
                    this.isEmail = false
                } else {
                    this.isEmail = true
                }
            } else {
                this.isEmail = false
            }
            return this.isEmail
        },
        itemSelected(hasSuggestions) {
            this.VerifyEmail(this.text)
            if (this.text == '') {
                this.hasSuggestions = false
            } else {
                const regex = /^[0-9]+$/;
                this.hasIdentifier = regex.test(this.text);
            }
            if (this.isEmail) {
                this.$emit('item-selected', { text: this.text, type: 'email' })
            } else {
                this.$emit('item-selected', {
                    text: this.text,
                    type: hasSuggestions ? 'filter' : (this.hasIdentifier ? 'identifier' : 'nickname'),
                })
            }
            if (hasSuggestions) this.reset()
        },
        autocompleteList(inp, arr) {
            this.autocompleteReady = true
            var currentFocus
            var vm = this
            inp.addEventListener('input', function (e) {
                var a,
                    b,
                    i,
                    val = this.value
                closeAllLists()
                if (!val) {
                    vm.itemSelected(true)
                    return false
                }
                currentFocus = -1
                a = document.createElement('DIV')
                a.setAttribute('id', this.id + 'autocomplete-list')
                a.setAttribute('class', 'autocomplete-items')
                this.parentNode.appendChild(a)
                vm.hasSuggestions = false
                for (i = 0; i < arr.length; i++) {
                    const valUpperCase = val.toUpperCase()
                    const textUpperCase = arr[i].toUpperCase()
                    if (textUpperCase.includes(valUpperCase)) {
                        const matchPosition = textUpperCase.indexOf(valUpperCase)
                        const match = '<strong>' + arr[i].substr(matchPosition, valUpperCase.length) + '</strong>'
                        const beforeMatch = arr[i].substr(0, matchPosition)
                        const afterMatch = arr[i].substr(matchPosition + valUpperCase.length)
                        b = document.createElement('DIV')
                        b.innerHTML = beforeMatch + match + afterMatch
                        b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>"
                        b.addEventListener('click', function (e) {
                            inp.value = this.getElementsByTagName('input')[0].value
                            closeAllLists()
                            vm.text = this.getElementsByTagName('input')[0].value
                            vm.itemSelected(true)
                        })
                        a.appendChild(b)
                        vm.hasSuggestions = true
                    }
                }
                vm.itemSelected(false)
            })
            inp.addEventListener('keydown', function (e) {
                var x = document.getElementById(this.id + 'autocomplete-list')
                if (x) x = x.getElementsByTagName('div')
                if (e.keyCode == 40) {
                    currentFocus++
                    addActive(x)
                } else if (e.keyCode == 38) {
                    currentFocus--
                    addActive(x)
                } else if (e.keyCode == 13) {
                    e.preventDefault()
                    if (currentFocus > -1) {
                        if (x) x[currentFocus].click()
                    }
                }
            })
            function addActive(x) {
                if (!x) return false
                removeActive(x)
                if (currentFocus >= x.length) currentFocus = 0
                if (currentFocus < 0) currentFocus = x.length - 1
                x[currentFocus].classList.add('autocomplete-active')
            }
            function removeActive(x) {
                for (var i = 0; i < x.length; i++) {
                    x[i].classList.remove('autocomplete-active')
                }
            }
            function closeAllLists(elmnt) {
                var x = document.getElementsByClassName('autocomplete-items')
                for (var i = 0; i < x.length; i++) {
                    if (elmnt != x[i] && elmnt != inp) {
                        x[i].parentNode.removeChild(x[i])
                    }
                }
            }
            document.addEventListener('click', function (e) {
                closeAllLists(e.target)
            })
        },
        verifyEmail(mail) {
            const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return mail !== '' && regex.exec(mail);
        }
    },
}
</script>

<style lang="scss">
.SearchBarFocusAnalysis {
    background: white !important;
    padding: 3px 9px !important;
    border-radius: 7px;
    border: none !important;
    height: 43px !important;
}

.SearchBarV2,
.SearchBarFocusAnalysis {
    .autocomplete-items {
        position: absolute;
        border: 1px solid #d4d4d4;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #d4d4d4;
    }

    /*when hovering an item:*/
    .autocomplete-items div:hover {
        background-color: #e9e9e9;
    }

    /*when navigating through the items using the arrow keys:*/
    .autocomplete-active {
        background-color: DodgerBlue !important;
        color: #ffffff;
    }
}
</style>
<style lang="scss" scoped>
.SearchBar {
    display: flex;
    position: relative;
    background: #f5f5f5;
    border: 1px solid #d3d3d3;
    padding: 11px 15px;
    width: 100%;
    height: 100%;

    input {
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        letter-spacing: 0.01em;
        color: #969696;
        opacity: 0.6;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 0;

        &::placeholder {
            color: #b3b3b3;
            font-size: 15px;
        }

        &:focus {
            outline: none;
        }
    }

    i {
        color: var(--primary-color);
        position: absolute;
        top: 25%;
        right: 10px;
        font-size: 20px;
    }
}
</style>
